@import 'theme.scss';

.MeasureCard {
  box-shadow: 0 0 8px 0 rgba(193, 193, 193, 0.5);
}

.MeasureCard__header {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  min-height: 8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
}

.MeasureCard h2 {
  margin: 0;
}

.MeasureCard h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.MeasureCard h4 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.MeasureCard__details {
  border-top: 0.1rem solid $color-gray;
  display: none;
  padding: 1rem;
}

.MeasureCard__details--open {
  display: block;
}

.MeasureCard__toggle {
  align-items: center;
  background: transparent;
  border-color: var(--color-primary);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  height: 1.75rem;
  justify-content: center;
  margin: 0 1rem 0 0.5rem;
  width: 1.75rem;
}

.MeasureCard__toggle:active,
.MeasureCard__toggle:focus,
.MeasureCard__toggle:hover {
  border-color: var(--color-primary-darkened);
  color: var(--color-primary-darkened);
}

.MeasureCard__endorsed-choice {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .MeasureCard {
    grid-gap: 1.5rem;
  }

  .MeasureCard h3 {
    font-size: 1.25rem;
  }
}

@media print {
  .MeasureCard {
    border: none;
    box-shadow: none;
    break-inside: avoid;
  }

  .MeasureCard h3 {
    font-size: 1.25rem;
  }

  .MeasureCard__details {
    display: none;
  }

  .MeasureCard__header {
    display: block;
    padding: 0;
    min-height: auto;
  }

  .MeasureCard__header p {
    font-size: 1.25rem;
    margin: 0.5rem 0 0 0;
  }

  .MeasureCard__toggle {
    display: none;
  }
}
