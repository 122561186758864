@import 'theme.scss';

.OfficeCard {
  align-items: center;
  border-radius: 0.25rem;
  box-shadow: 0 0 8px 0 $color-gray;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
}

.OfficeCard > h4 {
  flex: 1;
}

.OfficeCard__CandidateList {
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

.OfficeCard__CandidateList > li {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.OfficeCard__CandidateList > li > header {
  flex: 1;
  margin-left: 1rem;
}

@media (min-width: 768px) {
  .OfficeCard {
    flex-direction: row;
  }
}
