@import 'theme.scss';

.CandidateCard {
  box-shadow: 0 0 8px 0 rgba(193, 193, 193, 0.5);
}

.CandidateCard__header {
  align-items: center;
  display: grid;
  grid-template-columns: 8rem 1fr auto;
  grid-template-rows: minmax(8rem, max-content);
  grid-gap: 1rem;
}

.CandidateCard__header--no-headshot {
  grid-template-columns: 1fr auto;
  padding-left: 1rem;
}

.CandidateCard h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.CandidateCard__AlertIcon {
  color: var(--brand-warning);
}

.CandidateCard h4 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.CandidateCard__details {
  border-top: 0.1rem solid $color-gray-light;
  display: none;
  padding: 1rem;
}

.CandidateCard__details--open {
  display: block;
}

.CandidateCard__headshot {
  height: 8rem;
  object-fit: cover;
  width: 8rem;
}

.CandidateCard__toggle {
  align-items: center;
  background: transparent;
  border-color: var(--color-primary);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  height: 1.75rem;
  justify-content: center;
  margin: 0 1rem 0 0.5rem;
  width: 1.75rem;
}

.CandidateCard__toggle:active,
.CandidateCard__toggle:focus,
.CandidateCard__toggle:hover {
  border-color: var(--color-primary-darkened);
  color: var(--color-primary-darkened);
}

.CandidateCard__urls {
  margin-top: 0.5rem;
}

.CandidateCard__urls a {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.CandidateCard__issues {
  text-align: left;
}

.CandidateCard__issues-blob h1 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.5rem;
  text-align: left;
}

.CandidateCard__PartyInitials {
  margin-left: 0.375rem;
}

@media (min-width: 768px) {
  .CandidateCard {
    grid-gap: 1.5rem;
  }

  .CandidateCard h3 {
    font-size: 1.25rem;
  }
}

@media print {
  .CandidateCard {
    border: none;
    box-shadow: none;
    break-inside: avoid;
  }

  .CandidateCard h2 {
    margin-left: -1rem;
  }

  .CandidateCard h3 {
    font-size: 1.25rem;
    font-weight: normal;
  }

  .CandidateCard__details {
    display: none;
  }

  .CandidateCard__header {
    display: block;
  }

  .CandidateCard__headshot {
    display: none;
  }

  .CandidateCard__urls,
  .CandidateCard__toggle {
    display: none;
  }
}
